import React from "react";
import PropTypes from "prop-types";

// Utilities
import kebabCase from "lodash/kebabCase";

// Components
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site
  }
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Tags — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Tags index page of  " + site.siteMetadata.description}
        />
      </Helmet>
      <div className="tags-container">
        <center>
          <h1>Tags</h1>
         
            {group.map(tag => (
         
                <button className="squareTagButton tagsBox" >
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </button>
          
            ))}
         
        </center>
      </div>
    </Layout>
  );
};

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired
        }).isRequired
      )
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    })
  })
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
